<template>
  <v-row align="center" justify="center" class="fill-height">
    <v-progress-circular
      v-if="!$store.getters['display/isError']"
      :size="70"
      :width="7"
      color="primary"
      indeterminate>
    </v-progress-circular>
  </v-row>
</template>

<script>
  export default {

    name: "ProgressCircle"
  }
</script>

<style scoped>

</style>